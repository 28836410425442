.orders-subtable {
    padding: 1rem;
    width: 1100px;
}

.test1 {
    width: 140px !important;
}

.test2 {
    width: 180px !important;
}

.test3 {
    width: 210px !important;
}

.test4 {
    width: 180px !important;
}

.test5 {
    width: 180px !important;
}

.test6 {
    width: 180px !important;
}


.test {
    width: 20px !important;
}