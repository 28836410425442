.p-grey {
  background-color: #f2f2f2;
  padding: 0.5em;
}

.space-between {
  gap: 2em;
}

.editor-box {
  margin-top: 2em;
}

.no-wrap {
  flex-wrap: nowrap;
}

.batch {
  .batch-header {
    justify-content: space-between;
    flex-direction: column;

    .batch-title {
      width: 100%;

      @media (min-width: 768px) {
        width: 55%;
      }
    }

    .batch-header-buttons {
      button,
      span {
        font-size: 11px;

        @media (min-width: 768px) {
          font-size: 17.5px;
        }
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
}
