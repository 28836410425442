.batchDetails {
  display: flex;
  align-items: center;
  gap: 20px;

  .detailsLabel {
    min-width: 120px;
    max-width: 80px;
    margin-left: 10px;
  }

  .detailsData {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
