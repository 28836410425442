@import './AppDemo.scss';

@font-face {
  font-family: Roboto;
  src: url('/assets/fonts/Roboto-Regular.ttf');
}

body,
.p-component {
  font-family: Roboto;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

.pl-2 {
  padding-left: 10px;
}

.avatar-icon {
  left: 2px;
  position: relative;
  top: 2px;
}

.icon-hidden {
  visibility: hidden;
}

.layout-menu-logo {
  background: #d2ae0a !important;

  img {
    height: 40px !important;
  }
}

.layout-wrapper .layout-topbar {
  background: url('/assets/imgs/nav-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.layout-topbar-opcity {
  background: #f8c301d6;
  height: 60px;
  padding: 8px 25px;
}

//table-style-css

.p-datatable table {
  font-size: 12px;
}

.p-datatable .p-datatable-tbody > tr > th {
  padding: 10px 5px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid #d3d3d3;
  padding: 10px 5px;
}

.p-datatable .p-datatable-tbody > tr > td:first-child {
  border-left: 1px solid #d3d3d3 !important;
}

.p-datatable .p-datatable-tbody > tr > td:last-child {
  border-right: 1px solid #d3d3d3 !important;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: transparent !important;
}

.p-datatable-scrollable-body > table > .p-datatable-tbody > tr:first-child > td {
  border-top: 1px solid #d3d3d3 !important;
  word-wrap: break-word;
}

// .p-datatable-frozen-view > .p-datatable-scrollable-body > table > .p-datatable-tbody > tr > td:last-child {
//     border: 0 !important;
// }

// flex-box-css

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-jus-con {
  justify-content: center;
}

// display-text-css

.display-1 {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.2;
}

// border-style-css and padding-css

.border-bottom {
  border-bottom: 1px solid #d3d3d3;
  padding: 10px 0;
}

.py-2 {
  padding: 10px 0;
}

.my-2 {
  margin: 10px 0;
}

.mx-2 {
  margin: 0 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

// button-style-css

p.button.p-button-dark {
  background: #212121 !important;
  padding: 16px !important;
}

.icon-dark-color {
  color: #212121 !important;
}

.icon-info-color {
  background: #5a8ce6 !important;
  color: #ffffff !important;
  padding: 5px;
}

.icon-light-color {
  background: #b5b5b5 !important;
  color: #ffffff !important;
  padding: 5px;
}

.icon-success-color {
  background: #06b944 !important;
  color: #ffffff !important;
  padding: 5px;
}

.icon-warn-color {
  background: #df7c11 !important;
  color: #ffffff !important;
  padding: 5px;
}

.icon-inactive-color {
  background: #a0f1bd !important;
  color: #ffffff !important;
  padding: 5px;
}

// data-table-css-custom

.p-paginator {
  justify-content: end;
}

// custom-style-css

.p-inputtext {
  background: #f9f9f9;
  border-radius: 0;
  padding: 0.92rem 0.5rem;
}

.p-inputtext:enabled:focus {
  border-color: #212121;
  box-shadow: none;
}

.p-input-icon-left {
  width: 40%;

  .p-inputtext {
    background: #f2f2f2;
    border: 0;
    width: 100%;
  }
}

.p-input-icon-left.participants {
  width: 14%;
}

.p-datatable .p-datatable-header {
  background: transparent;
  border: 0;
  padding: 0 0 10px !important;
}

.p-button.p-component.p-autocomplete-dropdown.p-button-icon-only {
  background: #d3d3d3 !important;
  border: 1px solid #d3d3d3 !important;
  color: #646463;
}

.p-autocomplete {
  border-radius: 0;
  min-height: 46px;
  width: 100%;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  font-size: 16px;
  padding: 1.5rem;
}

.button-footer {
  bottom: 50px;
  position: fixed;
  // width: 100%;
}

.details-title {
  background: #ffffff;
  margin-bottom: 5px;
  padding: 10px 25px;
}

.cursor {
  cursor: pointer;
}

.layout-menu li a > .layout-menuitem-text {
  // color: #403F3F !important;
  font-weight: normal !important;
}

.layout-menu li a.active-route {
  background: #333333;

  .layout-menuitem-text {
    color: #ffffff !important;
  }
}

.layout-menuitem-text {
  padding-left: 10px;
}

.layout-menu li a:hover {
  .layout-menuitem-text {
    color: #ffffff !important;
  }
}

// .layout-wrapper.layout-menu-dark .layout-menu-container {
//     background: #ffffff;
// }

.layout-wrapper .layout-menu-container .layout-menu {
  padding: 14px 0 120px;
}

// .layout-wrapper .layout-menu-container .layout-menu li a, .layout-wrapper .layout-menu-container .layout-menu li .p-link {
//     border-radius: 0;
//     border-left: 5px solid #333;
//     margin-bottom: 3px;
// }

.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
  background: #495057;
  color: #ffffff;
  margin: 1px;
}

.p-button.p-button-icon-only.p-icon-btn-custom {
  border-radius: 0;
  padding: 2.3px 4px;
  width: auto;
}

.add-text-bg {
  background: linear-gradient(#5cff8d, #afffc7);
  padding: 5px;
}

.w-100 {
  width: 100%;
}

// .p-dropdown-label.p-inputtext {
//     padding: 6px;
// }

.p-dropdown {
  border-radius: 0;
  height: 44px;
}

.p-calendar {
  width: 100%;
}

.p-dropdown.p-component.p-inputwrapper.p-dropdown-clearable {
  width: 100%;
}

.comment-ul {
  padding: 0;

  li {
    border-bottom: 1px solid #d3d3d3;
    list-style: none;
    margin: 10px 0;
    padding: 15px 0;
  }
}

.p-dropdown:not(.p-disabled):active {
  border: 1px solid #333333;
}

.status-success {
  background: #c8e6c9;
  color: #256029;
  padding: 5px;
}

.status-danger {
  background: #ffcdd2;
  color: #c63737;
  padding: 5px;
}

.status-warning {
  background: #feedaf;
  color: #8a5340;
  padding: 5px;
}

.status-dark {
  background: #dddddd;
  color: #000000;
  padding: 5px;
}

.p-link:focus {
  box-shadow: none;
}

textarea {
  width: 100%;
}

.card-shadow {
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.161));
  margin-top: -10px;
}

.trek-coordinate {
  background: #bdbfbf;
  color: #000000;
  // font-weight: bold;
  letter-spacing: 1px;
  padding: 5px 10px;
}

.trek-equipment {
  background: #bdbfbf;
  color: #000000;
  // font-weight: bold;
  letter-spacing: 1px;
  padding: 5px 10px;
}

.pi-sort-alt::before,
.pi-sort-amount-up-alt::before {
  font-size: 10px;
}

.container {
  margin: 0 auto;
  max-width: 1440px;
  min-width: 1440px;
}

.p-autocomplete .p-autocomplete-panel {
  left: 0 !important;
  width: 70%;
}

.p-datatable-scrollable-header {
  position: sticky;
  top: 98px;
  width: 100%;
  z-index: 999;
}

.p-datatable-scrollable-header-box {
  margin-right: 0 !important;
}

.p-datatable-scrollable-wrapper {
  z-index: 9;
}

.loader-wrap {
  height: 100%;
  position: fixed;
  z-index: 100;
}

.loader-circle {
  align-items: center;
  background: #dfbd1c14;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  justify-content: center;
  width: calc(100vw - 32px);
}

.loader-circle i {
  color: #f8c301d6;
  font-size: 6rem;
}

@media (max-width: 640px) {
  .p-button-dark {
    padding: 10px !important;
  }

  .display-1 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .container {
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
  }
}
