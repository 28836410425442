.routes-btn-container {
  width: fit-content;
}

.modal-container {
  min-height: 80vh;
  min-width: 60vw;
}

.data-loader {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  margin-top: 15px;

  i {
    font-size: 5rem;
    font-weight: bold;
  }

  p {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.travels-table {
  border-spacing: 0;
  text-align: center;
  width: 100%;

  thead> tr> th {
    border-bottom: 2px solid #808080;
    border-right: 2px solid #808080;
    padding: 12px;
  }

  tbody tr th,
  tbody tr td {
    border: 1px solid #808080;
    padding: 8px;
  }

  tbody tr:nth-child(odd) {
    background: #f7f7f7;
  }

  .journey-point {
    font-weight: bold;
    margin: 0 2px;
  }

}

.price-input > input {
  padding: 0.5rem;
}

.no-close-warn {
  color: #ff0000;
}

.notice-text {
  color: #808080;
  font-size: 12px;
}

.location-icon {
  border-radius: 50%;
  margin-left: 4px;
}

.route-type {
  font-size: 12px;
}

.icon-tray {
  margin-bottom: 2rem;
  
  span {
    margin-right: 12px;
  }
}
