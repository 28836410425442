.test223 {
  color: #0000ff;
}

.trek-tags {
  background: var(--primary-color);
  border-radius: 5px;
  line-height: 2.4rem;
  margin-left: 5px;
  padding: 5px;
}

.trek-tags i {
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  margin-left: 5px;
}
